.contact-main{
    background-color: lightgray;
    /* border-radius: 20px; */
    width: max-content;
    display: flex;
    flex-direction: column;
    padding: 20px;
    position: absolute;
    top: 100px;
    left: 30px;
    /* transform: translate(-10%, -10%); */
}
input{
    border: 0px;
}
input:focus{
    border: 0px;
    outline: none;
}
textarea{
    border: 0px;
}
textarea:focus{
    border: 0px;
    outline: none;
}
button{
    border: 0px;
}
.title{
    font-size: 23px;
    font-weight: 900;
    margin: 10px 10px;
    box-shadow: inset 0  -12px hsla(85, 42%, 80%, 0.833);
    width: max-content;
    
}
.contact-main .first{
    display: flex;
    flex-direction: row;
    margin: 10px;
}

.contact-main .first div{
    display: flex;
    flex-direction: column;

}
.name-div{
    margin-right: 30px;
}

.email-div{
    margin-left: 30px;
}

.contact-main .second{
    margin: 10px;

}

.contact-main .second div{
    display: flex;
    flex-direction: column;
    position: relative;
}
.contact-main .second button{
    position: relative;
    right: 0px;
}

.email-button{
    width: 60px;
}