.network-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: max-content;

}

.input-form{
    display: flex;
    flex-direction: row;
    height: 80px;
    justify-content: center;

}

.input-form p{
    margin-top: 28px;
    /* font-weight: 1000; */
}

.input-form button{
    margin-top: 25px;
    width: 50px;
    height: 30px;
    margin-left: -40px;
    background-color: white;
    border: 1px solid black;
    border-radius: 3px;
}


.network-img-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 98%;
    height: 600px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #c0cacb;
    margin-bottom: 100px;
    background-color: #f4f4f5;
    position: relative;
    transition: opacity 1s ease ;
    cursor: pointer;
}

@starting-style{
    .network-img-div{
    opacity: 0;
    }
    
}


.network-img-div-images{
    display: flex;
    flex-direction: row;
    margin-top: -30px;
}
.input-form p{
    position: relative;
    padding-top: 3px;
    left: 40px;
}


.main-name{
    /* background-color: rebeccapurple; */
    margin-top: -10px;
    display: flex;
    position: relative;
    font-weight: 500;
    justify-content: center;

}
.main-name span{
    font-weight: 600;
    font-size: 30px;
    padding: 3px;
    box-shadow: inset 0 -15px #D1E9D2;
}

.actor-name{
    padding: 2px;
    margin: 20px 45px 0px 45px;
    box-shadow: inset 0  -5px hsla(23, 79%, 85%, 0.833);
    /* box-shadow: inset 0 -15px rgba(241, 199, 63, 0.45); */
    z-index: 10;
}

.movie-name{
    width: max-content;
    max-width: 60px;
    padding: 2px;
    margin: 20px 45px 0px 45px;
    box-shadow: inset 0  -5px hsla(241, 79%, 85%, 0.833);
    /* box-shadow: inset 0 -15px rgba(241, 199, 63, 0.45); */
    z-index: 10;
    word-break: break-all;
}


/* 
.line-img{
    margin: 10px 0px -20px 0px;
    z-index: 0;
    height: 50px;
} */


.line1-img{
    margin-top: 10px;
    width: 900px;
    z-index: 0;
    height: 20px;
}

.network-img-div-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 53px 30px 53px;
    border-radius: 10px;
    border:1px solid black;
    z-index: 10;
}
.network-mainimg-div-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 15px 0px 0px 0px;
    width: 300px;
    border-radius: 10px;
    text-align: center;
    
}

.main-img{
    width: 100px;
    /* height: 180px; */
    border-radius: 10px;
    border:2px solid black;
    z-index: 10;
}
.network-img-group{
    display: flex;
    flex-direction: row;
    /* border-radius: 10px; */
    /* margin: 20px; */
    padding: 0px;

}

.img{
    width: 80%;
    border:1px solid black;
    border-radius: 10px;
    /* margin: 30px 35px 0px 35px; */
    margin-top: 2px;
}

.group{
    /* background-color: aliceblue; */
    /* background-color: rgba(224, 228, 231, 0.425); */

    margin: 0px 78px;
    width: 80px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin: 30px; */

}
.group p{
    text-align: center;
    width: max-content;
    padding: 0px;
    margin: 0px;
    font-size: 8px;
}

.network-img-group-root{
    /* background-color: #5b6768; */
    display: flex;
    flex-direction: row;
    margin: 2px;
    justify-content: center;
    width: max-content;
}

.group-root{
    /* background-color: aliceblue; */
    margin: 10px;
    width: 40px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.group-root p{
    text-align: center;
    width: max-content;
    padding: 0px;
    margin: 0px;
    font-size: 8px;
}

.level1{
    display: flex;
    flex-direction: row;
}

.network-img-group-level1{
    /* background-color: skyblue; */
    display: flex;
    flex-direction: row;
    margin: 5px;
    width: max-content;
}

.group-level1{
    /* background-color: aliceblue; */
    margin: 5px;
    width: 40px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.group-level1 p{
    text-align: center;
    width: max-content;
    padding: 0px;
    margin: 0px;
    font-size: 8px;
}

.tree-root{
   display: flex;
   flex-direction: row; 
    background-color: rgba(224, 228, 231, 0.425);
    margin: 3px 3px 0px 3px;
 }
.tree{
    /* background-color: #D1E9D2; */
    display: flex;
    flex-direction: row;
}