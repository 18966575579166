.intro-div{
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    background-color: #EDEBEB;
}
.intro-div-text{
    margin: 30px 35px 0px 35px;
    color: black;
    opacity: 1;
    transition: opacity 1s ease ;
    font-size: 20px;
    background-color: rgba(255, 255, 255, 0.388);
    padding: 10px 30px 10px 30px;
    position: relative;
    height: max-content;

}
.last-text{
    position: absolute;;
    right: 10px;
}

.img-div{
    justify-content: center;
    display: flex;
    width: 100%;
}
.intro-img{
    margin-top: 30px;
    width: 650px;
    opacity: 1;
    transition: opacity 3s ease ;
}

.intro-img-mobile{
    margin-top: 30px;
    width: 350px;
    opacity: 1;
    transition: opacity 3s ease ;
}

@starting-style{
    .intro-div-text{
    opacity: 0;
    }
    .intro-img{
        opacity: 0;
        }
}


.intro-div-text-mobile{
    margin: 20px 30px 0px 30px;
    color: black;
    opacity: 1;
    transition: opacity 1s ease ;
    font-size: 15px;
}