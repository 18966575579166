.main-div{
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    text-align: center;
    opacity: 1;
    transition: opacity 1s ease ;
    background-color: #EDEBEB;
    position: absolute;
    width: 100vw;
    height: 87vh;
}


.main-div-mobile{
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    opacity: 1;
    transition: opacity 1s ease ;
    background-color: #EDEBEB;
    /* height: 87vh; */
    width: 100vw;
    position: relative;
}

@starting-style{
    .main-div{
    opacity: 0;
    }
    .main-div-mobile{
        opacity: 0;
        }
    .main-image{
        opacity: 0;
    }
}
.main-title{
    margin: 50px 0px 10px 0px;
    color: black;
    font-size: 36px;
    font-weight: 1000;
}

.main-title-mobile{
    margin: 30px 0px 10px 0px;
    color: black;
    font-size: 20px;
    font-weight: 1000;
}
.main-subtitle{
    color:black;
    font-size: 14px;
    font-weight: 200;
}

.main-input-field{
    margin-top: 20px;
}

.input-field-div{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.input-field-div-inner{
    display: flex;
    flex-direction: row;
}
.input-field-div-btn{
    justify-content: center;
    
}


.input-field-btn{
    width: 160px;
    height: 40px;
    color: black;
    border: 1px solid darkgray;
    background-color: #AAB2A8;
    border-radius: 20px;
    font-weight: 500;
}

.main-img-div{
    z-index: -10;
}

.main-image{
    width: 500px;
    opacity: 1;
    transition: opacity 10s ease ;
    margin-top: -30;
}

.main-image-moblie{
    width: 350px;
    opacity: 1;
    transition: opacity 10s ease ;
    /* margin-top: -30; */
}

.search-form-div{
    position: relative;
    /* margin: 20px 0px 0px 5%; */
    display: flex;
    width: 100%;
    height: max-content;
    right: 0px;
    background-color: #D9D9D9;
    flex-direction: column;
    text-align: left;
    /* border: 2px black solid; */
}

.search-form-choice{
    display: flex;
    flex-direction: row;

}
.search-form-title{
    padding: 10px 0px 10px 7px;
}
.search-form-choice-div{
    
    width: 49.5%;
    margin-right: 0.5%;
    background-color: azure;
    padding: 5px;
}
.search-form-div ul{
    padding: 0px;
    margin: 0px;
}
.search-form-div li{
    padding: 2px;
}

.main-left{
    display: flex;
    flex-direction: column;
    width: 80vw;
    z-index: 10;
    position: relative;
    /* background-color: lemonchiffon; */
}
.main-right{
    width: 20vw;
    margin: 20px;
    /* background-color: rebeccapurple; */
}

.inki1-image{
    position: absolute;
    left: 0px;
    bottom: 0px; 
    width: 22%;
    z-index: -10;
}
.inki2-image{
    position: absolute;
    right:  -20px;
    bottom: 0px;
    width: 22%;
    z-index: -10;
}

.tablet-main{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tabelt-form{
    position: absolute;
    bottom: 3%;
    width: 300px;
}


.inki-image-mobile{
    left: 30px;
    bottom: 0px; 
    /* z-index: -10; */
    /* width: 10px; */
    position: absolute;
}

.inki2-image-mobile{
    right: 30px;
    bottom: 0px; 
    /* z-index: -10; */
    /* width: 10px; */
    position: absolute;
}

.images{
    /* padding: 50px; */
    display: flex;
    /* background-color: antiquewhite; */
    height: 30vh;
}

.search-form-div li{
    list-style: none;
    cursor: pointer;
    color: black;
    /* text-decoration-style : dotted;
    text-decoration-line: none; */
    text-decoration: none;
}