.header-div{
    width: 100vw;
    height: 80px;
    display: flex;
    flex-direction: row;
    position: relative;
    border-bottom: 1px solid black;
    background-color: #D9D9D9;
    
    z-index: 10000;
}
.header-div-mobile{
    width: 100vw;
    height: 80px;
}

.header-title{
    font-size: 40px;
    font-weight: 800;
    color:black;
    padding: 15px 0px 0px 50px;
}

.header-menu-icon{
    display: flex;
    position: absolute;
    right: 20px;
    top: 30px;
}

.header-menu-div{
    background-color: white;
    z-index: 100;
    right: 0px;
    top: 90px;
    opacity: 0.95;
    position: absolute;
    height: max-content;
    box-shadow: -3px 3px 10px #D9D9D9;
    border-radius: 5px;
}



.header-menu{
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 50px;
    bottom: 0px;

    z-index: 10000;
}
